<template>
  <button
    @click="chooseRentType"
    :class="{ 'btn-primary--outline': !rentType.selected, 'btn-primary': rentType.selected }"
    class="btn"
    type="button"
  >
    {{ rentType.name }}
  </button>
</template>

<script>
export default {
  props: ['rentType'],
  methods: {
    chooseRentType() {
      this.$store.commit('v2/spaceManagerRequest/SET_SELECTED_RENT_TYPE', this.rentType.id);
    },
  },
};
</script>
